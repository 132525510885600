<template>
	<video-player  
		class="video-player vjs-big-play-centered"
		ref="videoPlayer"
		:src = "src"
		:poster = "poster"
		crossorigin="anonymous"
		:playsinline="true"
		controls
		@play="onPlayerPlay($event)"
		@pause="onPlayerPause($event)"
		@ended="onPlayerEnded($event)"
		@waiting="onPlayerWaiting($event)"
		@playing="onPlayerPlaying($event)"
		@loadeddata="onPlayerLoadeddata($event)"
		@timeupdate="onPlayerTimeupdate($event)"
		@canplay="onPlayerCanplay($event)"
		@canplaythrough="onPlayerCanplaythrough($event)"
		@statechanged="playerStateChanged($event)"
		@ready="playerReadied">
	</video-player>
</template>
  
<script>
	import { VideoPlayer } from '@videojs-player/vue'
	import 'video.js/dist/video-js.css'
	export default {
		props: {
			src: {
				type: String,
				default: null
			},
			poster: {
				type: String,
				default: null
			},
		},
		components: {
			VideoPlayer
		},
		data() {
			return {
				//src: "https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm",
				//poster: "images/logo.jpg"
			}
		},
		mounted() {
			console.log('this is current player instance object', this.player)
		},
		computed: {
			player() {
				return this.$refs.videoPlayer.player
			}
		},
		methods: {
			// listen event
			onPlayerPlay(player) {
				console.log('player play!', player)
			},
			onPlayerPause(player) {
				console.log('player pause!', player)
			},
			// ...player event
		
			// or listen state event
			playerStateChanged(playerCurrentState) {
				console.log('player current update state', playerCurrentState)
			},
			// player is ready
			playerReadied(player) {
				console.log('the player is readied', player)
				// you can use it to do something...
				// player.[methods]
			}
		}
	}
</script>
<style lang="scss" scoped>
  @import '@/assets/styles/_variables.scss';
  @import '@/assets/styles/sass/_mixins.scss';

  .video-player {
    background-color: transparent;
    width: 100%;
  }
</style>